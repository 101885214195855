<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="toCreate">发起教练申请</el-button>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder="选择状态" clearable>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="通过" :value="1"></el-option>
          <el-option label="拒绝" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="教练">
        <coachSelect :coachid.sync="coach_id"></coachSelect>
      </el-form-item>
      <el-form-item label="提交时间">
        <el-date-picker
          v-model="query.createTime"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
          <link-to :to="{ name: 'coachDetails', query: { id: row.coach_id } }">
            {{ row.coach_name }}</link-to
          >
        </div>
      </template>
      <template #status="{ row }">
        <el-tag size="small" type="info" v-if="row.status === 0">待审核</el-tag>
        <el-tag size="small" type="success" v-if="row.status === 1"
          >通过</el-tag
        >
        <el-tag size="small" type="danger" v-if="row.status === 2">拒绝</el-tag>
      </template>
      <template #handle="{ row }">
        <el-button
          size="small"
          v-if="row.status === 0"
          type="success"
          @click="updateStatus(row, 1)"
          >同意申请</el-button
        >
        <el-button
          size="small"
          v-if="row.status === 0"
          type="danger"
          @click="updateStatus(row, 2)"
          >拒绝申请</el-button
        >
        <el-button size="small" @click="openInfo(row)" type="primary"
          >详情</el-button
        >
      </template>
    </auto-table>

    <!-- 认证审核意见 -->
    <el-dialog title="认证审核意见" :visible.sync="show" width="30%">
      <div>
        <el-descriptions :column="1">
          <el-descriptions-item label="申请教练">{{
            curAuth.coach_name
          }}</el-descriptions-item>
          <el-descriptions-item label="申请说明">
            {{ curAuth.remark }}</el-descriptions-item
          >
          <el-descriptions-item label="审核结果">
            <el-tag size="small" type="info" v-if="curAuth.status === 0"
              >待审核</el-tag
            >
            <el-tag size="small" type="success" v-if="curAuth.status === 1"
              >通过</el-tag
            >
            <el-tag size="small" type="danger" v-if="curAuth.status === 2"
              >拒绝</el-tag
            ></el-descriptions-item
          >
          <el-descriptions-item label="审核人">
            {{ curAuth.admin_name }}</el-descriptions-item
          >
          <el-descriptions-item label="审核时间">
            {{ curAuth.update_time }}</el-descriptions-item
          >
          <el-descriptions-item label="审核说明">
            {{ curAuth.refuse }}</el-descriptions-item
          >
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="拒绝申请" :visible.sync="refuseShow" width="30%">
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入拒绝理由"
        v-model="refuse"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuseShow = false">取 消</el-button>
        <el-button type="primary" @click="refuseApply">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog width="30%" :visible.sync="updateShow" append-to-body>
      <el-form>
        <el-descriptions-item label="申请教练">{{
          curAuth.coach_name
        }}</el-descriptions-item>
        <el-form-item label="支付方式">
          <el-radio v-model="pay_type" label="immediate">立即支付</el-radio>
          <!-- <el-radio v-model="pay_type" label="deduct">收益扣除</el-radio> -->
        </el-form-item>
        <el-form-item label="扣款比例" v-if="pay_type == 'deduct'">
          <el-input
            type="Number"
            v-model="deduct_ratio"
            placeholder="请输入内容"
          ></el-input
          >%
          <div>还款比例、收益低于固定扣款金额时的比例</div>
        </el-form-item>
        <el-form-item label="课程扣款金额" v-if="pay_type == 'deduct'">
          <el-input
            type="Number"
            v-model="fixed_repayment"
            placeholder="请输入内容"
          ></el-input
          >元
        </el-form-item>
         <el-form-item label="产品名称" >
          {{product.name}}
        </el-form-item>
        <el-form-item label="费用：">
          <div>
            {{ create_price || "-" }}元
            <!-- <el-button
              v-permission="'editCreateUnionPrice'"
              type="text"
              @click="priceShow = !priceShow"
              >修改费用</el-button
            > -->
          </div>
          <el-input
            v-if="priceShow"
            type="Number"
            v-model="create_price"
            placeholder="创建费用"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateShow = false">取 消</el-button>
        <el-button type="primary" @click="agree">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from "@/components/select/coachSelect.vue";

export default {
  components: {
    coachSelect,
  },
  data() {
    return {
      refuseShow: false,
      refuse: "",
      DataList: [],
      coach_id: "",
      dev_ratio: 2,
      fixed_repayment: "",
      query: {
        status: 0,
        real_name: "",
        createTime: [],
        type: "",
      },
      typeMap: [
        {
          label: "ip服务",
          value: "coach_ip",
        },
      ],
      Option: [
        { name: "教练", value: "name", type: "custom" },
        { name: "电话", value: "map.coach.phone" },
        { name: "城市", value: "map.coach.city" },
        { name: "状态", value: "status", type: "custom" },
        { name: "提交人", value: "submit_name" },
        { name: "提交时间", value: "create_time" },
        { name: "审核时间", value: "update_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
      curAuth: {},
      curUnion: {},
      show: false,
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      pay_type: "immediate",
      ratio: 10,
      deduct_ratio: 50,
      updateShow: false,
      create_price: null,
      priceShow: false,
      product:{}
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    toCreate() {
      this.$router.push({
        name: "coachApplyip",
      });
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    openInfo(row) {
      this.curAuth = row;
      this.show = true;
    },
    refuseApply() {
      if (!this.refuse) {
        this.$message("请输入拒绝理由");
        return;
      }
      this.$axios({
        url: "/user/authApply/coachIPAudit",
        data: {
          id: this.curAuth.id,
          refuse: this.refuse,
          status: 2,
          admin_id: this.adminInfo.id,
          admin_name: this.adminInfo.name,
        },
        method: "post",
      }).then((res) => {
        this.getList(1);
        this.$message("拒绝成功");
        this.refuseShow = false;
      });
    },
    updateStatus(row, status) {
      this.curAuth = row;
     
      this.deduct_ratio = 50;
      if (status === 1) {
        this.$get('user/product/findById',{id:row.product_id}).then(res=>{
          this.product = res.data.data
					 this.create_price = this.product.discount_amount;
				})
        this.updateShow = true;
      } else {
        this.refuseShow = true;
      }
    },
    agree() {
      if (!this.pay_type) {
        this.$message("请选择支付类型");
        return;
      }
      if (this.pay_type == "deduct") {
        if (!this.deduct_ratio > 100 || this.deduct_ratio <= 10) {
          this.$message("支付比例最低10%");
          return;
        }
      }
      let data = {
        id: this.curAuth.id,
        status: 1,
        admin_id: this.adminInfo.id,
        admin_name: this.adminInfo.name,
        pay_type: this.pay_type,
        deduct_ratio: this.deduct_ratio / 100,
        fixed_repayment: this.fixed_repayment,
      };
      if (this.create_price) {
        data.create_price = this.create_price;
      }
      this.$axios({
        url: "/user/authApply/coachIPAudit",
        data,
        method: "post",
      }).then((res) => {
        this.getList(1);
        this.$message("通过成功");
        this.updateShow = false;
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        status: this.query.status,
        coach_id: this.coach_id || null,
        type: "coach_ip",
      };
      if (this.query.createTime !== null && this.query.createTime.length > 0) {
        params.start_time = this.query.createTime[0] + " 00:00:00";
        params.end_time = this.query.createTime[1] + " 23:59:59";
      }

      this.$axios({
        url: "/user/authApply/queryManagerListPage",
        params,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>